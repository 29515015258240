import React, { Component } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Archive extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Archive"
          description="The post archive of layerized.com"
          keywords={[]}
        />
        <h1>Archive</h1>
        <div className="archive">
          {this.props.data.allContentfulPost.edges.map(post => (
            <Link key={post.node.id} to={`/post/${post.node.slug}/`}>
              <h3>{post.node.title}</h3>
              <p>{post.node.description}</p>
            </Link>
          ))}
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    allContentfulPost(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          description
        }
      }
    }
  }
`

export default Archive
